import axios from "axios";
import { Api } from "../../../api/index.js";
import { Headers } from "../../../api/actions.js";

import React from "react";
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from "react-router-dom";
import ModalEditeModerators from "./modal-edit-moderators.jsx";
import Loading from "../../../components/loading/loading.jsx";
import axiosInstance from "../../../api/axios_instance.jsx";

const ModeratorsTable = (props) => {
  const {
    ArrayData,
    perpage,
    pagenumber,
    setLoading,
    PageSize,
    setPageSize,
    loadingRole,
  } = props;

  const { search, sortrole } = useParams();
  const searchKeyword = search.slice(search.indexOf(":") + 1, search.length);
  const sortRole = sortrole.slice(sortrole.indexOf(":") + 1, sortrole.length);
  let navigate = useNavigate();

  const handlePageClick = (data) => {
    let number = 1 + data.selected;
    navigate(
      `/moderators/page:${number}/search:${searchKeyword}/sortrole:${sortRole}`
    );
    setLoading(false);
  };

  if (!loadingRole) {
    return (
      <div className="table-container">
        <ModeratorsTableHead />
        <>
          <Loading />
        </>
      </div>
    );
  }
  return (
    <div className="table-container">
      <ModeratorsTableHead />
      <>
        <ul className="table-container__body">
          {ArrayData.map((item) => {
            return (
              <li key={item.id}>
                <ModeratorsTableBody Item={item} />
              </li>
            );
          })}
        </ul>
        <Paginate
          handlePageClick={handlePageClick}
          perpage={perpage}
          pagenumber={pagenumber}
          PageSize={PageSize}
          setPageSize={setPageSize}
        />
      </>
    </div>
  );
};

export default ModeratorsTable;

const ModeratorsTableHead = () => {
  return (
    <ul className="table-container__head">
      <li>
        <span className="justify-content-start">
          <label htmlFor="horns">الاسم</label>
        </span>
        <span>الرقم العسكري</span>
        <span> الصلاحية </span>
        <span className="justify-content-end">الإجراءات</span>
      </li>
    </ul>
  );
};

const ModeratorsTableBody = (props) => {
  const { Item } = props;
  return (
    <>
      <span className="justify-content-start">
        <label htmlFor="horns">{Item.fullName} </label>
      </span>
      <span>{Item.userName}</span>
      <span>{Item.rolesStr}</span>
      <span className="justify-content-end">
        <button
          type="button"
          className="btn btn-edit"
          data-bs-toggle="modal"
          data-bs-target={`#ModalEditeModerators-${Item.id}`}
          title="تعديل"
        >
          <img src="/assets/icons/icon-edit.svg" alt="edite" />
        </button>
        <button
          type="button"
          className="btn btn-remove"
          data-bs-toggle="modal"
          data-bs-target="#delete-user"
          title="حذف"
        >
          <img src="/assets/icons/icon-delete.svg" alt="remove" />
        </button>
      </span>
      <ModalEditeModerators Item={Item} />
      <ModalDelete Item={Item} />
    </>
  );
};

function Paginate(props) {
  const { handlePageClick, perpage, pagenumber, PageSize, setPageSize } = props;
  const SelectValue = [
    {
      id: 1,
      value: 10,
      title: "10 صفوف",
    },
    {
      id: 2,
      value: 20,
      title: "20 صفوف",
    },
    {
      id: 3,
      value: 30,
      title: "30 صفوف",
    },
    {
      id: 4,
      value: 40,
      title: "40 صفوف",
    },
    {
      id: 5,
      value: 50,
      title: "50 صفوف",
    },
  ];

  return (
    <div className="paginate_control">
      <div className="paginate_control__select">
        <label htmlFor="">
          <span>عدد الصفوف</span>
        </label>
        <select
          className="form-select"
          onChange={(e) => {
            setPageSize(parseInt(e.target.value));
          }}
          value={PageSize}
        >
          <>
            {SelectValue.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              );
            })}
          </>
        </select>
      </div>
      <ReactPaginate
        previousLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9 18L15 12L9 6"
              stroke="#64748B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        nextLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 6L9 12L15 18"
              stroke="#94A3B8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        breakLabel={"..."}
        pageCount={perpage}
        postsPerPage={2}
        pageSize={1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        renderOnZeroPageCount={null}
        forcePage={pagenumber - 1}
      />
    </div>
  );
}

const DeletData = async (id) => {
  var data = new FormData();
  data.append("id", id);

  const options = {
    method: "post",
    url: `${Api}ApplicationDbUser/DeleteUser`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      window.location.reload();
    })
    .catch(function (error) {});
};

const ModalDelete = (props) => {
  const { Item } = props;
  return (
    <div
      className="modal fade"
      id="delete-user"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0 mb-3 mt-3">
            <h5
              id="staticBackdropLabel"
              className="w-100 text-center modal-title"
            >
              حذف المشرف
            </h5>
          </div>
          <div className="modal-body border-0 mb-3">
            <p className="w-100 text-center">
              هل أنت متأكد من حذف هذا المشرف؟ .
            </p>
          </div>
          <div className="modal-footer border-0 mb-3 d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              إلغاء
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => {
                DeletData(Item.id);
              }}
            >
              حذف
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
