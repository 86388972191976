/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Api } from "../../../api/index.js";
import { Headers } from "../../../api/actions.js";
import Loading from "../../../components/loading/loading";

import NavbarLeft from "../../../components/navbar/navbar_left";
import NavbarTop from "../../../components/navbar/navbar_top";
import { useParams } from "react-router";
import ListSurveyStudentsContent from "../component/list-survey-students";
import axiosInstance from "../../../api/axios_instance.jsx";

const ListSurveyStudents = () => {
  const {
    page,
    search,
    sortclassroom,
    subjectid,
    surveytypeid,
    sortclassroomnumberid,
  } = useParams();
  const pageCount = page.slice(page.indexOf(":") + 1, page.length);
  const searchKeyword = search.slice(search.indexOf(":") + 1, search.length);
  const classRoom = sortclassroom.slice(
    sortclassroom.indexOf(":") + 1,
    sortclassroom.length
  );
  const classRoomNumber = sortclassroomnumberid.slice(
    sortclassroomnumberid.indexOf(":") + 1,
    sortclassroomnumberid.length
  );

  const subject = subjectid.slice(subjectid.indexOf(":") + 1, subjectid.length);
  const surveyTypeId = surveytypeid.slice(
    surveytypeid.indexOf(":") + 1,
    surveytypeid.length
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perpage, setPerpage] = useState(0);
  const [PageSize, setPageSize] = useState(10);

  const ObJectArrayData = [
    {
      id: 2,
      href: `/survey`,
      title: "الاستبيانات",
    },
  ];
  useEffect(() => {
    GetData(
      pageCount,
      searchKeyword,
      classRoom,
      subject,
      surveyTypeId,
      PageSize,
      setPerpage,
      setData,
      setLoading,
      classRoomNumber
    );
  }, [pageCount, PageSize, searchKeyword]);

  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <ListSurveyStudentsContent
            ArrayData={data}
            perpage={perpage}
            pagenumber={pageCount}
            setLoading={setLoading}
            PageSize={PageSize}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </section>
  );
};

export default ListSurveyStudents;

const GetData = async (
  pageCount,
  searchKeyword,
  classRoom,
  subject,
  surveyTypeId,
  PageSize,
  setPerpage,
  setData,
  setLoading,
  classroomNumberId
) => {
  const options = {
    method: "post",
    url: `${Api}Survey/SearchForSurveyStudent`,
    headers: Headers,
    data: {
      pageNumber: pageCount,
      searchKeyword: searchKeyword,
      classRoomId: classRoom,
      subjectId: subject,
      pageSize: PageSize,
      surveyTypeId: surveyTypeId,
      ClassRoomNumberId: classroomNumberId,
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.items);
      setLoading(true);

      setPerpage(
        Math.ceil(
          response.data.responseObject.totalItemsCount /
            response.data.responseObject.pageSize
        )
      );
    })
    .catch(function (error) {
      setLoading(true);
    });
};
