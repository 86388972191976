import React from "react";
import QuestionCard from "./question-card";

const ChoiceAddQuestionContent = ({ data }) => {
  return (
    <div className="add-question-content">
      <EditeQuestionTop />
      <QuestionCard data={data} />
    </div>
  );
};
export default ChoiceAddQuestionContent;

const EditeQuestionTop = () => {
  return (
    <div className="add-question__top d-flex justify-content-between align-items-center w-100">
      <h5 className="size-h5"> تعديل سؤال</h5>
    </div>
  );
};
