import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);
export const ChartSurveyQuestions = (props) => {
  const { Arraydata } = props;
  const dataLoop = [...Arraydata.data];
  const dataLabels = [...Arraydata.label];
  const backgroundColor = [
    "#5570f1",
    "#ffb500",
    "#02b60a",
    "#ff2f2f",
    "#917373",
    "#5570f1",
    "#ffb500",
    "#02b60a",
    "#ff2f2f",
  ];

  const data = {
    labels: dataLabels,
    animations: {
      tension: {
        duration: 500,
        easing: "linear",
        from: 1,
        to: 0,
        loop: true,
      },
    },
    scales: {
      y: {
        min: 0,
        max: 50,
      },
    },
    datasets: [
      {
        label: dataLabels,
        backgroundColor: backgroundColor,
        borderColor: "none",
        borderWidth: 0,
        data: dataLoop.map((itm) => +itm),
      },
    ],
    options: {
      datalabels: {
        display: true,
      },
    },
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        position: "right",
        rtl: true,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 2,
          font: {
            size: 2,
          },
        },
      },
      datalabels: {
        borderWidth: (a) => (a.dataset.data[a.dataIndex] === 0 ? 0 : 1),
        color: "white",
        font: {
          weight: "bold",
        },
        padding: 0,

        formatter: (value) => {
          if (+value > 0) {
            let percentage = value + "%";
            return percentage;
          }
          return "";
        },
      },
    },
  };
  return (
    <div
      className="home-content__chart_row"
      style={{
        width: "auto",
      }}
    >
      <h3>أسئلة الاستبيانات</h3>
      <div className="home-content__chart-list">
        <div className="home-content__chart-list__item">
          <div className="cartitem">
            <div className="list">
              {dataLabels.map((label, index) => (
                <div className="list-item" key={index}>
                  <span
                    className="scroil"
                    style={{
                      backgroundColor: data.datasets[0].backgroundColor[index],
                    }}
                  ></span>
                  <span className="label">{label}</span>
                </div>
              ))}
            </div>
            {!dataLoop?.length || dataLoop.every((itm) => itm === "0") ? (
              <NoDataPie />
            ) : (
              <Pie data={data} options={options} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export const ChartQuestionnaireStatistics = (props) => {
  const { Arraydata } = props;
  const dataLoop = [...Arraydata.data];
  const dataLabels = [...Arraydata.label];

  const backgroundColor = [
    "#5570f1",
    "#ffb500",
    "#02b60a",
    "#ff2f2f",
    "#917373",
    "#5570f1",
    "#ffb500",
    "#02b60a",
    "#ff2f2f",
  ];

  const data = {
    labels: dataLabels,
    animations: {
      tension: {
        duration: 500,
        easing: "linear",
        from: 1,
        to: 0,
        loop: true,
      },
    },
    scales: {
      y: {
        min: 0,
        max: 50,
      },
    },
    datasets: [
      {
        label: dataLabels,
        backgroundColor: backgroundColor,
        borderColor: "none",
        borderWidth: 0,
        data: dataLoop,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
        position: "right",
        rtl: true,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 2,
          font: {
            size: 2,
          },
        },
      },
      datalabels: {
        borderWidth: (a) => (a.dataset.data[a.dataIndex] === 0 ? 0 : 1),
        color: "white",
        font: {
          weight: "bold",
        },
        padding: 0,

        formatter: (value) => {
          if (+value > 0) {
            let percentage = value + "%";
            return percentage;
          }
          return "";
        },
      },
    },
  };
  return (
    <div
      className="home-content__chart_row"
      style={{
        width: "auto",
      }}
    >
      {/* */}
      <h3
        style={{
          height: "22px",
        }}
      ></h3>
      <div className="home-content__chart-list">
        <div className="home-content__chart-list__item">
          <div className="cartitem">
            <div className="list">
              {dataLabels.map((label, index) => (
                <div className="list-item" key={index}>
                  <span
                    className="scroil"
                    style={{
                      backgroundColor: data.datasets[0].backgroundColor[index],
                    }}
                  ></span>
                  <span className="label">{label}</span>
                </div>
              ))}
            </div>
            {!dataLoop?.length || dataLoop.every((itm) => itm === "0") ? (
              <NoDataPie />
            ) : (
              <Pie data={data} options={options} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const NoDataPie = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        aspectRatio: "1/1",
        background: "#000",
        color: "white",
        borderRadius: "9999px",
        justifyContent: "center",
        margin: "0 24px",
        minWidth: "300px",
      }}
    >
      {" "}
      لا توجد بيانات
    </div>
  );
};
