import React, { useEffect, useState } from "react";
import { Api } from "../../../api";
import { Headers } from "../../../api/actions";
import axios from "axios";
import Loading from "../../../components/loading/loading";
import ChartRow from "../../../components/home-sections/chart-sections";
import axiosInstance from "../../../api/axios_instance";

const StudentChart = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetData(setData, setLoading);
  }, []);

  if (!loading) {
    return (
      <>
        <Loading />
      </>
    );
  }
  return (
    <>
      <ChartRow Arraydata={data} />
    </>
  );
};

export default StudentChart;

const GetData = async (setData, setLoading) => {
  const options = {
    method: "POST",
    url: `${Api}Charts/StudentChart`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      const res = response.data.responseObject;
      res.classRoom?.label?.splice(0, 1);
      res.classRoom?.data?.splice(0, 1);
      setData(res);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
