/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../../components/loading/loading";
import axios from "axios";
import { Headers } from "../../../../api/actions";
import { Api } from "../../../../api";
import ReactPaginate from "react-paginate";
import axiosInstance from "../../../../api/axios_instance";

function AddFormCardFive(props) {
  const { arrayStudents, setArrayStudents, Error, setError } = props;
  const { id } = useParams();
  const Id = id.slice(id.indexOf(":") + 1, id.length);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [perpage, setPerpage] = useState(0);
  const [pagenumber, setPageNumber] = useState(1);

  useEffect(() => {
    GetData(Id, setData, setLoading, pagenumber, setPerpage);
  }, [Id, pagenumber]);

  const handlePageClick = (data) => {
    let number = 1 + data.selected;
    setPageNumber(number);
    setLoading(false);
  };

  if (!loading) {
    return (
      <div className="exams-content-add__form__card">
        <Loading />
      </div>
    );
  }
  return (
    <div className="exams-content-add__form__table">
      <div className="table-container">
        <AddAllQuestionTableHead />
        <ul
          className="table-container__body"
          style={{
            minHeight: "max-content",
          }}
        >
          {data?.map((item, index) => {
            return (
              <li key={index}>
                <AddAllQuestionTableBody
                  Item={item}
                  arrayStudents={arrayStudents}
                  setArrayStudents={setArrayStudents}
                  Error={Error}
                  setError={setError}
                />
              </li>
            );
          })}
        </ul>
        {Error.arrayStudents && (
          <span className="text-danger w-100">{Error.arrayStudents}</span>
        )}
        <br />
        <Paginate
          handlePageClick={handlePageClick}
          perpage={perpage}
          pagenumber={pagenumber}
        />
      </div>
    </div>
  );
}

export default AddFormCardFive;

const AddAllQuestionTableHead = () => {
  return (
    <ul className="table-container__head">
      <li>
        <span className="justify-content-start">اسم الطالب</span>
        <span>رقم الهوية</span>
        <span>الحالة</span>
        <span className="justify-content-end">الإجراءات</span>
      </li>
    </ul>
  );
};

const AddAllQuestionTableBody = (props) => {
  const { Item, arrayStudents, setArrayStudents, Error, setError } = props;
  return (
    <>
      <span className="justify-content-start">{Item.studentName}</span>
      <span className="text-end">{Item.nationalId}</span>
      <span className="text-end">{Item.statusInTest}</span>
      <span className="justify-content-end">
        <button
          type="button"
          className={
            arrayStudents.find((x) => x === Item.studentId)
              ? "btn btn-repetiton btn-add-clicked"
              : "btn btn-repetiton"
          }
          onClick={(e) => {
            e.preventDefault();
            setError({ ...Error, arrayStudents: "" });
            if (arrayStudents.find((x) => x === Item.studentId)) {
              setArrayStudents(
                arrayStudents.filter((x) => x !== Item.studentId)
              );
            } else {
              setArrayStudents([...arrayStudents, Item.studentId]);
            }
          }}
        >
          {arrayStudents.find((x) => x == Item.studentId)
            ? "تمت الاضافه"
            : "أضافه الى الامتحان"}
        </button>
      </span>
    </>
  );
};

function Paginate(props) {
  const { handlePageClick, perpage, pagenumber } = props;

  return (
    <div className="paginate_control justify-content-end">
      <ReactPaginate
        previousLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9 18L15 12L9 6"
              stroke="#64748B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        nextLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 6L9 12L15 18"
              stroke="#94A3B8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        breakLabel={"..."}
        pageCount={perpage}
        postsPerPage={2}
        pageSize={1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        renderOnZeroPageCount={null}
        forcePage={pagenumber - 1}
      />
    </div>
  );
}

const GetData = async (Id, setData, setLoading, pagenumber, setPerpage) => {
  const options = {
    method: "post",
    url: `${Api}Test/GetAbsentStudentsByTestId`,
    headers: Headers,
    data: {
      testId: Id,
      searchKeyword: "",
      pageSize: 50,
      pageNumber: pagenumber,
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.items);
      setLoading(true);
      setPerpage(
        Math.ceil(
          response.data.responseObject.items.length /
            response.data.responseObject.pageSize
        )
      );
    })
    .catch(function (error) {
      setLoading(true);
    });
};
