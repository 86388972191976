import React, { useEffect, useState } from "react";
import AddFormCardOne from "./form-card-one";
import AddFormCardTwo from "../add/add-form-card-two";
import AddFormCardFour from "../exam-repetiton/repetiton-form-card-four";
import { useNavigate, useParams } from "react-router-dom";
import { Headers } from "../../../../api/actions";
import { Api } from "../../../../api";
import axios from "axios";
import swal from "sweetalert";
import axiosInstance from "../../../../api/axios_instance";
import AddFormCardThree from "../add/add-form-card-three";

const ExamsEditForm = (props) => {
  const { Arraydata } = props;
  const { id, classroomid, subjectid } = useParams();

  const navigate = useNavigate();
  const Subjectid = subjectid.slice(
    subjectid.indexOf(":") + 1,
    subjectid.length
  );
  const classroomId = classroomid.slice(
    classroomid.indexOf(":") + 1,
    classroomid.length
  );

  const timeString = Arraydata.testStartTime;
  const timeString12hr = new Date(
    "1970-01-01T" + timeString + "Z"
  ).toLocaleTimeString("en-US", {
    timeZone: "UTC",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });

  const [day, setDay] = useState(new Date(Arraydata.testDate));
  const [testDuration, setTestDuration] = useState(
    Arraydata.testDurationInMinutes
  );

  const [startTime, setStartTime] = useState(
    simulateTime(Arraydata.testStartTime)
  );

  const [numberDelivery, setNumberDelivery] = useState(
    Arraydata.numberOfMatchingQuestionDegree
  );
  const [numberMultipleChoice, setMultipleChoice] = useState(
    Arraydata.numberOfMultiChoiceQuestionDegree
  );
  const [numberTrueAndFalse, setNumberTrueAndFalse] = useState(
    Arraydata.numberOfTrueFalseQuestionDegree
  );
  const [degreeDelivery, setDegreeDelivery] = useState(
    Arraydata.matchingQuestionDegree
  );
  const [degreeMultipleChoice, setDegreeMultipleChoice] = useState(
    Arraydata.multiChoiceQuestionDegree
  );
  const [degreeTrueAndFalse, setDegreeTrueAndFalse] = useState(
    Arraydata.trueFalseQuestionDegree
  );
  const [matchingCount, setMatchingCount] = useState(100);

  const [trueAndFalseCount, setTrueAndFalseCount] = useState(100);

  const [multiChoiceCount, setMultiChoiceCount] = useState(100);

  const [isRandomQuestions, setIsRandomQuestions] = useState(
    Arraydata.isRandomQuestions
  );
  const [isShowResult, setIsShowResult] = useState(
    Arraydata.isShowRandomToStudent
  );

  const [arrayQuestions, setArrayQuestions] = useState([
    ...Arraydata.questions,
  ]);
  const numberClassRoom = Arraydata.classRoomNumberIds?.[0];

  const [error, setError] = useState({
    day: "",
    testDuration: "",
    startTime: "",
    numberQuen: "",
    arrayQuestions: "",
  });
  const CheckEmptyValue = () => {
    if (day === null) {
      setError({ ...error, day: "يجب اختيار اليوم" });
    } else if (testDuration === "") {
      setError({ ...error, testDuration: "يجب ادخال مدة الامتحان" });
    } else if (startTime === null) {
      setError({ ...error, startTime: "يجب ادخال وقت بداية الامتحان" });
    } else if (
      numberDelivery === "" &&
      numberMultipleChoice === "" &&
      numberTrueAndFalse === ""
    ) {
      setError({ ...error, numberQuen: "يجب ادخال دراجات الاسئلة" });
    } else if (arrayQuestions.length === 0 && isRandomQuestions === false) {
      setError({ ...error, arrayQuestions: "يجب اختيار الاسئلة" });
    } else {
      setError({
        day: "",
        testDuration: "",
        startTime: "",
        numberQuen: "",
        arrayQuestions: "",
      });
      SenData(
        id,
        Subjectid,
        classroomId,
        numberClassRoom,
        day,
        testDuration,
        startTime,
        numberDelivery,
        degreeDelivery,
        numberMultipleChoice,
        degreeMultipleChoice,
        numberTrueAndFalse,
        degreeTrueAndFalse,
        isRandomQuestions,
        isShowResult,
        arrayQuestions,
        Arraydata,
        navigate
      );
    }
  };
  const OnSubmit = (e) => {
    e.preventDefault();
    CheckEmptyValue();
  };
  const OnCansel = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <div className="exams-content-add__form">
      <AddFormCardOne
        classroomId={classroomId}
        subjectId={Subjectid}
        Day={day}
        setDay={setDay}
        testDuration={testDuration}
        setTestDuration={setTestDuration}
        startTime={startTime}
        setStartTime={setStartTime}
        startTimePlaceHolder={timeString12hr}
        Error={error}
        numberClassRoom={numberClassRoom}
        setError={setError}
      />
      <br />
      <AddFormCardTwo
        numberDelivery={numberDelivery}
        setNumberDelivery={setNumberDelivery}
        numberMultipleChoice={numberMultipleChoice}
        setMultipleChoice={setMultipleChoice}
        numberTrueAndFalse={numberTrueAndFalse}
        setNumberTrueAndFalse={setNumberTrueAndFalse}
        degreesDelivery={degreeDelivery}
        setDegreesDelivery={setDegreeDelivery}
        degreesMultipleChoice={degreeMultipleChoice}
        setDegreesMultipleChoice={setDegreeMultipleChoice}
        degreesTrueAndFalse={degreeTrueAndFalse}
        setDegreesTrueAndFalse={setDegreeTrueAndFalse}
        isRandomQuestions={isRandomQuestions}
        setIsRandomQuestions={setIsRandomQuestions}
        setArrayQuestions={setArrayQuestions}
        arrayQuestions={arrayQuestions}
        Error={error}
        setError={setError}
        matchingCount={matchingCount}
        multiChoiceCount={multiChoiceCount}
        trueAndFalseCount={trueAndFalseCount}
      />
      {/* <AddFormCardTwo
        numberDelivery={numberDelivery}
        setNumberDelivery={setNumberDelivery}
        numberMultipleChoice={numberMultipleChoice}
        setMultipleChoice={setMultipleChoice}
        numberTrueAndFalse={numberTrueAndFalse}
        setNumberTrueAndFalse={setNumberTrueAndFalse}
        isRandomQuestions={isRandomQuestions}
        setIsRandomQuestions={setIsRandomQuestions}
        arrayQuestions={arrayQuestions}
        setArrayQuestions={setArrayQuestions}
        Error={error}
        setError={setError}
        matchingCount={matchingCount}
        multiChoiceCount={multiChoiceCount}
        trueAndFalseCount={trueAndFalseCount}
        degreeDelivery={degreeDelivery}
        setDegreeDelivery={setDegreeDelivery}
        degreeMultipleChoice={degreeMultipleChoice}
        setDegreeMultipleChoice={setDegreeMultipleChoice}
        degreeTrueAndFalse={degreeTrueAndFalse}
        setDegreeTrueAndFalse={setDegreeTrueAndFalse}
      /> */}

      <AddFormCardThree
        isRandomQuestions={isRandomQuestions}
        arrayQuestions={arrayQuestions}
        setArrayQuestions={setArrayQuestions}
        subject={Subjectid}
        Error={error}
        setError={setError}
        setMatchingCount={setMatchingCount}
        setMultiChoiceCount={setMultiChoiceCount}
        setTrueAndFalseCount={setTrueAndFalseCount}
      />
      <AddFormCardFour
        isShowRandomToStudent={isShowResult}
        setIsShowRandomToStudent={setIsShowResult}
      />
      {/* <AddFormCardFour
        isShowResult={isShowResult}
        setIsShowResult={setIsShowResult}
      /> */}
      <div className="exams-content-add__form__buttons">
        <button className="btn btn-primary" onClick={OnSubmit}>
          حفظ
        </button>
        <button className="btn btn-secondary" onClick={OnCansel}>
          إلغاء
        </button>
      </div>
    </div>
  );
};

export default ExamsEditForm;

const SenData = async (
  id,
  Subjectid,
  classroomId,
  numberClassRoom,
  day,
  testDuration,
  startTime,
  numberDelivery,
  degreeDelivery,
  numberMultipleChoice,
  degreeMultipleChoice,
  numberTrueAndFalse,
  degreeTrueAndFalse,
  isRandomQuestions,
  isShowResult,
  arrayQuestions,
  Arraydata,
  navigate
) => {
  const TimeStringHours =
      startTime.getHours() < 10
        ? `0${startTime.getHours()}`
        : startTime.getHours(),
    TimeStringMinutes =
      startTime.getMinutes() < 10
        ? `0${startTime.getMinutes()}`
        : startTime.getMinutes();
  const Month =
    day.getMonth() + 1 < 10 ? `0${day.getMonth() + 1}` : day.getMonth() + 1;
  const Day = day.getDate() < 10 ? `0${day.getDate()}` : day.getDate();

  const DayIso = `${day.getFullYear()}-${Month}-${Day}`;
  const TimeMinutes = `${TimeStringHours}:${TimeStringMinutes}`;

  const options = {
    method: "post",
    url: `${Api}Test/AddEditTest`,
    headers: Headers,
    data: {
      id: id,
      subjectId: Subjectid,
      classRoomIds: [classroomId],
      ClassRoomNumberIds: [numberClassRoom],
      testDate: DayIso,
      testStartTime: TimeMinutes,
      testDurationInMinutes: testDuration,
      // isShowResult: isShowResult,
      multiChoiceQuestionDegree: degreeMultipleChoice,
      numberOfMultiChoiceQuestionDegree: numberMultipleChoice,
      trueFalseQuestionDegree: degreeTrueAndFalse,
      numberOfTrueFalseQuestionDegree: numberTrueAndFalse,
      matchingQuestionDegree: degreeDelivery,
      numberOfMatchingQuestionDegree: numberDelivery,
      isRandomQuestions: isRandomQuestions,
      questions: arrayQuestions,
      ClassRoomNumber: numberClassRoom,
      isShowRandomToStudent: isShowResult,
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description === "Error") {
        swal(`${response.data.responseStatus.errors[0].errorMessage}`, {
          icon: "error",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "إغلاق",
        });
      } else {
        swal("تم تعديل الامتحان بنجاح ", {
          icon: "success",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            navigate(-1);
          }
        });
      }
    })

    .catch(function (error) {});
};

const simulateTime = (str) => {
  const str1 = str.split(" ")[0];
  const d = new Date();
  d.setHours(str1?.split(":")[0] ?? 0, str1?.split(":")[1] ?? 0);
  return d;
};
