import React, { useEffect, useState } from "react";
import { Headers } from "../../../../../api/actions";
import { Api } from "../../../../../api";
import axios from "axios";
import { useParams, useNavigate } from "react-router";
import swal from "sweetalert";
import axiosInstance from "../../../../../api/axios_instance";
import { useSearchParams } from "react-router-dom";

const TrueAndFalseCard = (props) => {
  const { Type, setType, question, setQuestion, answers } = props;
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const subjectId = searchParams.get("subjectId");
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const [answerAll, setAnswerAll] = useState(answers);
  const onStatusChange = (val) => {
    if (val === "صح")
      setAnswerAll([
        {
          answerId: Math.random() * 1000,
          name: "صح",
          isCorrectAnswer: true,
        },
        {
          answerId: Math.random() * 1000,
          name: "خطأ",
          isCorrectAnswer: false,
        },
      ]);
    else if (val === "خطأ")
      setAnswerAll([
        {
          answerId: Math.random() * 1000,
          name: "صح",
          isCorrectAnswer: false,
        },
        {
          answerId: Math.random() * 1000,
          name: "خطأ",
          isCorrectAnswer: true,
        },
      ]);
  };

  const SubmitAction = (e) => {
    if (question === "") {
      setError(true);
      swal("يجب ادخال نص السؤال", {
        icon: "warning",
        confirmButtonColor: "#d33",
        button: {
          text: "إغلاق",
          closeModal: true,
        },
      });
      return;
    } else {
      // check if the answer is correct one item only
      let correctAnswer = answerAll.filter(
        (item) => item.isCorrectAnswer === true
      ); // check if the answer namer is empty
      let answerName = answerAll.filter((item) => item.name === "");
      if (answerName.length !== 0) {
        swal("يجب ادخال جميع الاجابات", {
          icon: "warning",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        });
        return;
      } else if (correctAnswer.length !== 1) {
        swal("يجب اختيار اجابة صحيحه و اجابة خاطئة ", {
          icon: "warning",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        });
        return;
      } else {
        setError(false);
        e.preventDefault();
        SendData(Type, id, subjectId, question, answerAll, navigate);
      }
    }
  };

  return (
    <div className="add-question-content__card">
      <h6>تعديل سؤال</h6>
      {/* Question Name */}
      <textarea
        rows="3"
        value={question}
        placeholder="اكتب نص السؤال"
        name="add-question-textarea"
        required
        className={
          question === "" && error === true
            ? "form-control is-invalid"
            : "form-control"
        }
        onChange={(e) => {
          e.preventDefault();
          setQuestion(e.target.value);
        }}
      ></textarea>
      <SelectBoxTypeAnswer Type={Type} setType={setType} />
      <>
        {/* {answerAll.map((item, index) => {
          return (
            <div key={index}>
              <MultipleChoiceInputRow
                setAnswerAll={setAnswerAll}
                answerAll={answerAll}
                Item={item}
                Index={index}
              />
            </div>
          );
        })} */}
        <SelectBoxAnswerStatus
          onStatusChange={onStatusChange}
          defaultValue={
            answerAll?.find((answer) => answer.isCorrectAnswer)?.name
          }
        />
        <ButtonsAll SubmitAction={SubmitAction} />
      </>
    </div>
  );
};

export default TrueAndFalseCard;

const SelectBoxTypeAnswer = (props) => {
  const { Type, setType } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    GetData(setData, setLoading);
  }, []);
  if (loading === false) {
    return (
      <div className="select-box">
        <label htmlFor="">نوع السؤال</label>
        <input
          type="text"
          name=""
          id=""
          className={" form-control"}
          value={"جاري التحميل"}
          disabled
        />
      </div>
    );
  }
  return (
    <div className="select-box" style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          left: "20px",
          bottom: "50px",
          color: "gray",
        }}
      >
        ⮟
      </div>
      <label htmlFor="">نوع السؤال</label>
      <select
        name=""
        id=""
        className={"form-control"}
        defaultValue={Type}
        onChange={(e) => {
          e.preventDefault();
          setType(e.target.value);
        }}
        disabled={true}
      >
        {data.map((item, index) => {
          return (
            <option key={index} value={item.id}>
              {item.nameAr}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export const SelectBoxAnswerStatus = (props) => {
  const { onStatusChange, defaultValue } = props;
  const data = ["صح", "خطأ"];
  return (
    <div className="select-box" style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          left: "20px",
          bottom: "50px",
          color: "gray",
        }}
      >
        ⮟
      </div>
      <label htmlFor="">حالة السؤال</label>
      <select
        name=""
        id=""
        className={"form-control"}
        defaultValue={defaultValue || "صح"}
        onChange={(e) => {
          e.preventDefault();
          onStatusChange(e.target.value);
        }}
      >
        {data.map((item) => {
          return (
            <option key={item} value={item}>
              {item}
            </option>
          );
        })}
      </select>
    </div>
  );
};

const MultipleChoiceInputRow = (props) => {
  const { setAnswerAll, answerAll, Item, Index } = props;
  const [select, setSelect] = useState(Item.isCorrectAnswer);

  const SelectArray = [
    {
      id: 1,
      value: true,
      name: "صحيح",
    },
    {
      id: 2,
      value: false,
      name: "خطأ",
    },
  ];

  const onChingeSelect = (e) => {
    e.preventDefault();
    setSelect(!select);
    Item.isCorrectAnswer = !select;
    answerAll[Index] = Item;
    setAnswerAll([...answerAll]);
  };
  return (
    <div className="multiple-choice__row">
      <div className="select">
        <label htmlFor="">حاله الأجابه </label>
        <select
          name=""
          id=""
          value={select}
          className={select === true ? "success" : "error"}
          onChange={onChingeSelect}
        >
          {SelectArray.map((item, index) => {
            return (
              <option key={index} value={item.value}>
                {item.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="inputs">
        <label htmlFor="">الأجابه</label>
        <input
          disabled={true}
          value={Item.name}
          type="text"
          name=""
          id=""
          className={
            select === true ? "success form-control" : "error form-control"
          }
          onChange={(e) => {
            e.preventDefault();
            Item.name = e.target.value;
          }}
        />
      </div>
    </div>
  );
};

const ButtonsAll = (props) => {
  const { SubmitAction } = props;
  const navigate = useNavigate();

  return (
    <div className="add-question-content__card__buttons">
      <button
        type="button"
        className="btn btn-save"
        onClick={(e) => {
          SubmitAction(e);
        }}
      >
        <span>حفظ</span>
      </button>
      <button
        type="button"
        className="btn btn-cansal"
        onClick={(e) => {
          e.preventDefault();
          navigate(-1);
        }}
      >
        <span>الغاء</span>
      </button>
    </div>
  );
};

// Send Data to API
const SendData = async (Type, id, SubjectId, question, answerAll, navigate) => {
  var Formdata = new FormData();
  for (var i = 0; i < answerAll.length; i++) {
    Formdata.append("id", Math.random() * 1000);
    Formdata.append(`answers[${i}].name`, JSON.stringify(answerAll[i].name));
    Formdata.append(
      `answers[${i}].isCorrectAnswer`,
      answerAll[i].isCorrectAnswer
    );
  }

  const options = {
    method: "post",
    url: `${Api}Question/AddEditQuestion`,
    headers: Headers,
    data: {
      id,
      name: question,
      subjectId: SubjectId,
      answerTypeId: Number(Type),
      answers: answerAll,
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      swal("تم تعديل السؤال بنجاح", {
        icon: "success",
        confirmButtonColor: "#d33",
        button: {
          text: "إغلاق",
          closeModal: true,
        },
      }).then((result) => {
        if (result === true) {
          navigate(-1);
        }
      });
    })
    .catch(function (error) {});
};

const GetData = async (setData, setLoading) => {
  const options = {
    method: "GET",
    url: `${Api}AnswerType/GetAnswerTypes`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
