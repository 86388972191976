/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { Api } from "../../../api";
import { Headers } from "../../../api/actions";
import axios from "axios";
import axiosInstance from "../../../api/axios_instance";
import { FilterReset } from "../../shared/reset-filter";

const ChartTop = (props) => {
  const [classRoomId, setClassRoomId] = useState("");
  const {
    surveyTypeId,
    setSurveyTypeId,
    classRoomCode,
    setClassRoomCode,
    classRoomNumber,
    setClassRoomNumber,
    classRoomName,
    setClassRoomName,
  } = props;
  return (
    <div className="home-content__top">
      <QuestionnaireType
        surveyTypeId={surveyTypeId}
        setSurveyTypeId={setSurveyTypeId}
      />
      <CourseCode
        setClassRoomId={setClassRoomId}
        classRoomCode={classRoomCode}
        setClassRoomCode={setClassRoomCode}
      />
      {/* <Course
        classRoomName={classRoomName}
        setClassRoomName={setClassRoomName}
      /> */}
      {classRoomId && (
        <CourseNumber
          classRoomId={classRoomId}
          classRoomNumber={classRoomNumber}
          setClassRoomNumber={setClassRoomNumber}
        />
      )}
      {(surveyTypeId || classRoomCode || classRoomNumber) && (
        <FilterReset
          resetFilters={() => {
            setSurveyTypeId(null);
            setClassRoomCode(null);
            setClassRoomNumber(null);
            setClassRoomId(null);
          }}
        />
      )}
    </div>
  );
};
export default ChartTop;

const QuestionnaireType = (props) => {
  const { surveyTypeId, setSurveyTypeId } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataSurveyTypes(setData, setLoading);
  }, []);

  if (!loading) {
    return (
      <div className="dropdown">
        <button className="btn btn-show-dropdown" type="button">
          <span className="text">نوع الاستبيان</span>
        </button>
      </div>
    );
  }
  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="text">نوع الاستبيان</span>
      </button>
      <ul className="dropdown-menu">
        {data.map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={item.id}
                checked={surveyTypeId == item.id}
                id="flexCheckDefault"
                onChange={(e) => {
                  e.preventDefault();
                  setSurveyTypeId(item.id);
                }}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>{item.name}</span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
const CourseCode = (props) => {
  const { classRoomCode, setClassRoomCode, setClassRoomId } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleData = (data) => {
    if (data) setData(removeDuplicates(data, "code"));
  };
  useEffect(() => {
    GetDataClassRooms(handleData, setLoading);
  }, []);

  if (!loading) {
    return (
      <div className="dropdown">
        <button className="btn btn-show-dropdown" type="button">
          <span className="text">اسم الدورة</span>
        </button>
      </div>
    );
  }
  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="text">اسم الدورة</span>
      </button>
      <ul
        className="dropdown-menu"
        style={{ maxHeight: "400px", overflowY: "auto" }}
      >
        {data.map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={item.id}
                id="CourseCode"
                checked={classRoomCode == item.code}
                onChange={(e) => {
                  e.preventDefault();
                  setClassRoomCode(item.code);
                  setClassRoomId(item.id);
                }}
              />
              <label className="form-check-label" htmlFor="CourseCode">
                <span>
                  {item.nameAr} - {item.code}
                </span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
const Course = (props) => {
  const { classRoomName, setClassRoomName } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleData = (data) => {
    if (data) setData(removeDuplicates(data, "nameAr"));
  };
  useEffect(() => {
    GetDataClassRooms(handleData, setLoading);
  }, []);

  if (!loading) {
    return (
      <div className="dropdown">
        <button className="btn btn-show-dropdown" type="button">
          <span className="text">الدورة</span>
        </button>
      </div>
    );
  }

  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="text">الدورة</span>
      </button>
      <ul className="dropdown-menu">
        {data.map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={item.id}
                id="flexCheckDefault"
                checked={classRoomName == item.nameAr}
                onChange={(e) => {
                  e.preventDefault();
                  setClassRoomName(item.nameAr);
                }}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>
                  {item.nameAr} - {item.code}
                </span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
const CourseNumber = (props) => {
  const { classRoomNumber, setClassRoomNumber, classRoomId } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleData = (data) => {
    if (data) setData(removeDuplicates(data, "number"));
  };
  useEffect(() => {
    if (classRoomId)
      GetDataClassRoomsNumbers(classRoomId, handleData, setLoading);
    else setData([]);
    setClassRoomNumber("");
  }, [classRoomId]);

  if (!loading) {
    return (
      <div className="dropdown">
        <button className="btn btn-show-dropdown" type="button">
          <span className="text">رقم الدورة</span>
        </button>
      </div>
    );
  }

  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="text"> رقم الدورة </span>
      </button>
      <ul className="dropdown-menu">
        {data.map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={item.id}
                id="flexCheckDefault"
                checked={classRoomNumber == item.number}
                onChange={(e) => {
                  e.preventDefault();
                  setClassRoomNumber(item.number);
                }}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>{item.number}</span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
const MaterialSymbol = (props) => {
  const { subjectCode, setSubjectCode } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataSubjects(setData, setLoading);
  }, []);

  if (!loading) {
    return (
      <div className="dropdown">
        <button className="btn btn-show-dropdown" type="button">
          <span className="text">رمز الماده</span>
        </button>
      </div>
    );
  }

  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="text">رمز الماده</span>
      </button>
      <ul className="dropdown-menu">
        {data.map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={item.code}
                id="flexCheckDefault"
                checked={subjectCode == item.code}
                onChange={(e) => {
                  e.preventDefault();
                  setSubjectCode(item.code);
                }}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>{item.code}</span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
const Material = (props) => {
  const { subjectName, setSubjectName } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataSubjects(setData, setLoading);
  }, []);

  if (!loading) {
    return (
      <div className="dropdown">
        <button className="btn btn-show-dropdown" type="button">
          <span className="text"> الماده </span>
        </button>
      </div>
    );
  }

  return (
    <div className="dropdown">
      <button
        className="btn btn-show-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="text"> الماده </span>
      </button>
      <ul className="dropdown-menu">
        {data.map((item, index) => {
          return (
            <li key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={item.id}
                id="flexCheckDefault"
                checked={subjectName == item.nameAr}
                onChange={(e) => {
                  e.preventDefault();
                  setSubjectName(item.nameAr);
                }}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <span>{item.nameAr}</span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
// Get Data To Api
// get Survey Types
const GetDataSurveyTypes = async (setData, setLoading) => {
  const options = {
    method: "GET",
    url: `${Api}Survey/GetSurveyTypes`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
// get Class Rooms
const GetDataClassRooms = async (setData, setLoading) => {
  var data = new FormData();
  data.append("pageNumber", 1);
  data.append("searchKeyword", "");
  data.append("pageSize", 10000000);
  const options = {
    method: "post",
    url: `${Api}ClassRoom/SearchForClassRooms`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.items);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
const GetDataClassRoomsNumbers = async (classRoomId, setData, setLoading) => {
  var data = new FormData();
  data.append("pageNumber", 1);
  data.append("searchKeyword", "");
  data.append("pageSize", 50);
  data.append("classRoomId", classRoomId);

  const options = {
    method: "post",
    url: `${Api}ClassRoom/GetClassRoomNumbersListById`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.items);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
// get Subjects
const GetDataSubjects = async (setData, setLoading) => {
  var data = new FormData();
  data.append("pageNumber", 1);
  data.append("searchKeyword", "");
  data.append("classRoomId", 0);
  data.append("pageSize", 10);

  const options = {
    method: "post",
    url: `${Api}Subject/SearchForSubjects`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.items);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};

function removeDuplicates(arr, key) {
  // Create an empty object to track seen values
  const seen = {};

  // Use the filter method to return only unique objects
  return arr.filter((item) => {
    // Check if the key's value has been seen before
    if (seen[item[key]]) {
      return false;
    } else {
      // If not seen, mark it as seen and include it in the result
      seen[item[key]] = true;
      return true;
    }
  });
}
