import { createSlice } from "@reduxjs/toolkit";

const initialState = () =>
  localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {
        username: null,
        menu: null,
        token: null,
        roles: null,
      };

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    signIn: (state, action) => {
      const user = {
        username: action.payload.username,
        militaryNumber: action.payload.militaryNumber,
        menu: action.payload.menu,
        token: action.payload.token,
        roles: action.payload.roles,
      };
      localStorage.setItem("user", JSON.stringify(user));
      return user;
    },
    signOut: (state, action) => {
      localStorage.removeItem("user");
      return {
        username: null,
        menu: null,
        token: null,
        roles: null,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { signIn, signOut } = userSlice.actions;

export default userSlice.reducer;
