import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import IconSearch from "../../../components/shared/icon-search";
const RoundsTop = (props) => {
  const { Title, setLoading } = props;
  let navigate = useNavigate();
  const { id, search: searchKey } = useParams();
  const [search, setSearch] = useState(searchKey.split(":")[1] ?? "");
  const SearchClick = (e) => {
    e.preventDefault();
    navigate(`/courses/rounds/${id}/page:${1}/search:${search}`);
  };

  return (
    <div className="d-flex justify-content-between align-items-center w-100">
      <h5 className="size-h5">
        الدورات ل <span> ({Title})</span>
      </h5>
      <div className="input-group-search">
        <IconSearch SearchClick={SearchClick} />
        <input
          type="text"
          placeholder="بحث"
          defaultValue={search}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              navigate(
                `/courses/rounds/${id}/page:${1}/search:${e.target.value}`
              );
              setLoading(false);
            }
            setSearch(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default RoundsTop;
