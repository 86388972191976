import React, { useEffect, useState } from "react";
import AddFormCardOne from "../exam-edit/form-card-one";
import AddFormCardTwo from "../add/add-form-card-two";
import AddFormCardFour from "./repetiton-form-card-four";
import AddFormCardThree from "../add/add-form-card-three";
import AddFormCardFive from "./repetiton-form-card-five";
import { useNavigate, useParams } from "react-router-dom";
import { Headers } from "../../../../api/actions";
import { Api } from "../../../../api";
import axios from "axios";
import swal from "sweetalert";
import axiosInstance from "../../../../api/axios_instance";

const ExamsRepetitonForm = (props) => {
  const { Arraydata } = props;

  const { classroomid, subjectid } = useParams();
  const navigate = useNavigate();
  const Subjectid = subjectid.slice(
    subjectid.indexOf(":") + 1,
    subjectid.length
  );
  const classroomId = classroomid.slice(
    classroomid.indexOf(":") + 1,
    classroomid.length
  );
  const timeString12hr = "اختر وقت الامتحان";
  const [day, setDay] = useState(null);
  const [testDuration, setTestDuration] = useState("");

  const [startTime, setStartTime] = useState(null);

  const [numberDelivery, setNumberDelivery] = useState(0);
  const [matchingCount, setMatchingCount] = useState(0);
  const [numberMultipleChoice, setMultipleChoice] = useState(0);
  const [multiChoiceCount, setMultiChoiceCount] = useState(0);
  const [numberTrueAndFalse, setNumberTrueAndFalse] = useState(0);
  const [trueAndFalseCount, setTrueAndFalseCount] = useState(0);

  const [degreesDelivery, setDegreesDelivery] = useState(
    Arraydata?.matchingQuestionDegree || 0
  );
  const [degreesMultipleChoice, setDegreesMultipleChoice] = useState(
    Arraydata?.multiChoiceQuestionDegree || 0
  );
  const [degreesTrueAndFalse, setDegreesTrueAndFalse] = useState(
    Arraydata?.trueFalseQuestionDegree || 0
  );

  const [isRandomQuestions, setIsRandomQuestions] = useState(false);
  const [showPrfewQuestions, setShowPrfewQuestions] = useState(false);
  // عرض الاسئله للطالب بنفس الترتيب الذي تم ادخالها
  const [isShowRandomToStudent, setIsShowRandomToStudent] = useState(true);
  const numberClassRoom = Arraydata.classRoomNumberIds?.[0];

  useEffect(() => {
    setShowPrfewQuestions(false);
  }, [
    numberDelivery,
    matchingCount,
    numberMultipleChoice,
    multiChoiceCount,
    numberTrueAndFalse,
    trueAndFalseCount,
    degreesDelivery,
    degreesMultipleChoice,
    degreesTrueAndFalse,
  ]);
  const [isShowResult, setIsShowResult] = useState(false);

  const [arrayQuestions, setArrayQuestions] = useState(
    Arraydata?.questions || []
  );
  const [arrayStudents, setArrayStudents] = useState([]);

  const [error, setError] = useState({
    day: "",
    testDuration: "",
    startTime: "",
    numberQuen: "",
    arrayStudents: "",
    arrayQuestions: "",
  });
  const OnSubmit = (e) => {
    e.preventDefault();
    if (day === null) {
      setError({ ...error, day: "يجب اختيار اليوم" });
    } else if (testDuration === "") {
      setError({ ...error, testDuration: "يجب ادخال مدة الامتحان" });
    } else if (startTime === null) {
      setError({ ...error, startTime: "يجب ادخال وقت بداية الامتحان" });
    } else if (
      numberDelivery === 0 &&
      numberMultipleChoice === 0 &&
      numberTrueAndFalse === 0 &&
      isRandomQuestions
    ) {
      setError({ ...error, numberQuen: "يجب ادخال دراجات الاسئلة" });
    } else if (arrayStudents.length === 0) {
      setError({ ...error, arrayStudents: "يجب اختيار الطلاب" });
    } else if (arrayQuestions.length === 0 && isRandomQuestions === false) {
      setError({ ...error, arrayQuestions: "يجب اختيار الاسئلة" });
    } else {
      SenData(
        Subjectid,
        classroomId,
        numberClassRoom,
        day,
        testDuration,
        startTime,
        numberDelivery,
        degreesDelivery,
        numberMultipleChoice,
        degreesMultipleChoice,
        numberTrueAndFalse,
        degreesTrueAndFalse,
        isRandomQuestions,
        isShowResult,
        arrayQuestions,
        arrayStudents,
        isShowRandomToStudent,
        navigate
      );
    }
  };
  const OnCansel = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <div className="exams-content-add__form">
      <AddFormCardOne
        classroomId={classroomId}
        subjectId={Subjectid}
        Day={day}
        setDay={setDay}
        testDuration={testDuration}
        setTestDuration={setTestDuration}
        startTime={startTime}
        setStartTime={setStartTime}
        startTimePlaceHolder={timeString12hr}
        Error={error}
        numberClassRoom={numberClassRoom}
        setError={setError}
      />
      <br />
      <AddFormCardTwo
        numberDelivery={numberDelivery}
        setNumberDelivery={setNumberDelivery}
        numberMultipleChoice={numberMultipleChoice}
        setMultipleChoice={setMultipleChoice}
        numberTrueAndFalse={numberTrueAndFalse}
        setNumberTrueAndFalse={setNumberTrueAndFalse}
        degreesDelivery={degreesDelivery}
        setDegreesDelivery={setDegreesDelivery}
        degreesMultipleChoice={degreesMultipleChoice}
        setDegreesMultipleChoice={setDegreesMultipleChoice}
        degreesTrueAndFalse={degreesTrueAndFalse}
        setDegreesTrueAndFalse={setDegreesTrueAndFalse}
        isRandomQuestions={isRandomQuestions}
        setIsRandomQuestions={setIsRandomQuestions}
        setArrayQuestions={setArrayQuestions}
        arrayQuestions={arrayQuestions}
        Error={error}
        setError={setError}
        matchingCount={matchingCount}
        multiChoiceCount={multiChoiceCount}
        trueAndFalseCount={trueAndFalseCount}
      />
      {/* <AddFormCardTwo
        numberDelivery={numberDelivery}
        setNumberDelivery={setNumberDelivery}
        numberMultipleChoice={numberMultipleChoice}
        setMultipleChoice={setMultipleChoice}
        numberTrueAndFalse={numberTrueAndFalse}
        setNumberTrueAndFalse={setNumberTrueAndFalse}
        isRandomQuestions={isRandomQuestions}
        setIsRandomQuestions={setIsRandomQuestions}
        setArrayQuestions={setArrayQuestions}
        Error={error}
        setError={setError}
      /> */}

      <AddFormCardFive
        arrayStudents={arrayStudents}
        setArrayStudents={setArrayStudents}
        Error={error}
        setError={setError}
      />

      <AddFormCardThree
        isRandomQuestions={isRandomQuestions}
        arrayQuestions={arrayQuestions}
        setArrayQuestions={setArrayQuestions}
        subject={Subjectid}
        Error={error}
        setError={setError}
        setMatchingCount={setMatchingCount}
        setMultiChoiceCount={setMultiChoiceCount}
        setTrueAndFalseCount={setTrueAndFalseCount}
      />
      <AddFormCardFour
        isShowRandomToStudent={isShowRandomToStudent}
        setIsShowRandomToStudent={setIsShowRandomToStudent}
      />
      <div className="exams-content-add__form__buttons">
        <button className="btn btn-primary" onClick={OnSubmit}>
          حفظ
        </button>
        <button className="btn btn-secondary" onClick={OnCansel}>
          إلغاء
        </button>
      </div>
    </div>
  );
};

export default ExamsRepetitonForm;

const SenData = async (
  Subjectid,
  classroomId,
  numberClassRoom,
  day,
  testDuration,
  startTime,
  numberDelivery,
  degreesDelivery,
  numberMultipleChoice,
  degreesMultipleChoice,
  numberTrueAndFalse,
  degreesTrueAndFalse,
  isRandomQuestions,
  isShowResult,
  arrayQuestions,
  arrayStudents,
  isShowRandomToStudent,
  navigate
) => {
  const Month =
    day.getMonth() + 1 < 10 ? `0${day.getMonth() + 1}` : day.getMonth() + 1;
  const Day = day.getDate() < 10 ? `0${day.getDate()}` : day.getDate();

  const DayIso = `${day.getFullYear()}-${Month}-${Day}`;
  const TimeStringHours = startTime.getHours(),
    TimeStringMinutes = startTime.getMinutes();

  const TimeMinutes = `${TimeStringHours}:${TimeStringMinutes}`;

  const options = {
    method: "post",
    url: `${Api}Test/ReAddEditTest`,
    headers: Headers,
    data: {
      subjectId: Subjectid,
      classRoomIds: [classroomId],
      ClassRoomNumberIds: [numberClassRoom],
      testDate: DayIso,
      testStartTime: TimeMinutes,
      testDurationInMinutes: testDuration,
      isShowResult: isShowResult,
      numberOfMultiChoiceQuestionDegree: numberMultipleChoice,
      numberOfTrueFalseQuestionDegree: numberTrueAndFalse,
      trueFalseQuestionDegree: degreesTrueAndFalse,
      matchingQuestionDegree: degreesDelivery,
      multiChoiceQuestionDegree: degreesMultipleChoice,
      numberOfMatchingQuestionDegree: numberDelivery,
      isRandomQuestions: isRandomQuestions,
      questions: arrayQuestions,
      ClassRoomNumber: numberClassRoom,
      students: arrayStudents,
      isShowRandomToStudent: isShowRandomToStudent,
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description === "Error") {
        swal(`${response.data.responseStatus.errors[0].errorMessage}`, {
          icon: "error",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "إغلاق",
        });
      } else {
        swal("تم اضافة امتحان بنجاح للطلاب المحددين", {
          icon: "success",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            navigate(-1);
          }
        });
      }
    })

    .catch(function (error) {});
};
