import React, { useEffect, useState } from "react";
import axios from "axios";
import { Api } from "../../../api/index.js";
import { Headers } from "../../../api/actions";
import Loading from "../../../components/loading/loading";

import NavbarLeft from "../../../components/navbar/navbar_left";
import NavbarTop from "../../../components/navbar/navbar_top";
import { useParams } from "react-router";
import ListStudentsContent from "../component/liststudents/list-students-content";
import axiosInstance from "../../../api/axios_instance.jsx";
import TabToggler from "../../../components/shared/tab-toggler.jsx";

const ListStudents = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const tabs = ["الطلاب المتغيبين", "الطلاب الممتحينين"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [test, setTest] = useState();
  const ObJectArrayData = [
    {
      id: 2,
      href: `/exams/page:${1}/search:${""}`,
      title: "الاختبارات",
    },
  ];
  const onTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === "الطلاب الممتحينين") GetData(id, setData, setLoading);
    else if (tab === "الطلاب المتغيبين")
      GetAbsentStudentData(id, setData, setLoading);
  };
  useEffect(() => {
    onTabChange(tabs[0]);
    GetTestData(id, setTest);
  }, [id]);

  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <TabToggler
            tabs={tabs}
            onTabChange={onTabChange}
            activeTab={activeTab}
          >
            <ListStudentsContent
              setLoading={setLoading}
              ArrayData={data}
              reTest={
                activeTab === "الطلاب المتغيبين" && data?.length && test <= 0
              }
            />
          </TabToggler>
        </div>
      </div>
    </section>
  );
};

export default ListStudents;

const GetData = async (id, setData, setLoading) => {
  setLoading(false);
  var data = new FormData();
  data.append("testId", id);
  data.append("searchKeyword", "");

  const options = {
    method: "post",
    url: `${Api}Test/GetAllStudentsByTestId`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.items);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
const GetTestData = async (id, setData) => {
  const options = {
    method: "post",
    url: `${Api}Test/GetTestById`,
    headers: Headers,
    data: {
      id: id,
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(new Date(response.data.responseObject.endDate) - new Date());
      // setLoading(true);
    })
    .catch(function (error) {
      // setLoading(true);
    });
};

const GetAbsentStudentData = async (id, setData, setLoading) => {
  setLoading(false);
  var data = new FormData();
  data.append("testId", id);
  data.append("searchKeyword", "");

  const options = {
    method: "post",
    url: `${Api}Test/GetAbsentStudentsByTestId`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.items);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
