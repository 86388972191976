import React from "react";
import Question from "./question";

const QuestionCard = (props) => {
  const { data } = props;
  return (
    <div className="add-question-content__card">
      <h6>تعديل سؤال</h6>
      <Question data={data} />
    </div>
  );
};

export default QuestionCard;
