import React from "react";
import RoundsTop from "./rounds-top";
import RoundsTable from "./rounds-table";

const RoundsContent = (props) => {
  const { ArrayData, perpage, pagenumber, setLoading, PageSize, setPageSize } =
    props;
  return (
    <div className="courses-content">
      {
        <RoundsTop
          setLoading={setLoading}
          Title={
            (ArrayData?.[0]?.nameAr || "") +
            " - " +
            (ArrayData?.[0]?.code || "")
          }
        />
      }
      <RoundsTable
        ArrayData={ArrayData}
        perpage={perpage}
        pagenumber={pagenumber}
        setLoading={setLoading}
        PageSize={PageSize}
        setPageSize={setPageSize}
      />
    </div>
  );
};

export default RoundsContent;
