/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Api } from "../../../api/index";
import { Headers } from "../../../api/actions";
import swal from "sweetalert";
import Select from "react-select";
import axiosInstance from "../../../api/axios_instance";

const ModalAddModerators = () => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [ErrorInput, setErrorInput] = useState({
    name: "",
    number: "",
    password: "",
    confirmpassword: "",
  });
  const [Validity, setValidity] = useState("");
  const [ErrorValidity, setErrorValidity] = useState("");
  const [errorfiled, setErrorfiled] = useState("");

  const OnSubmit = (e) => {
    e.preventDefault();
    setErrorInput({
      name: "",
      number: "",
      password: "",
      confirmpassword: "",
    });

    if (name === "") {
      setErrorInput((prevState) => ({
        ...prevState,
        name: "الاسم مطلوب",
      }));
    } else if (number === "") {
      setErrorInput((prevState) => ({
        ...prevState,
        number: "الرقم العسكري مطلوب",
      }));
    } else if (password === "") {
      setErrorInput((prevState) => ({
        ...prevState,
        password: "كلمة المرور مطلوبة",
      }));
    } else if (confirmpassword === "") {
      setErrorInput((prevState) => ({
        ...prevState,
        confirmpassword: "كلمة المرور مطلوبة",
      }));
    } else if (Validity === "") {
      setErrorValidity("الصلاحية مطلوبة");
    } else {
      setErrorValidity("");
      CreateAccount(
        name,
        number,
        password,
        confirmpassword,
        Validity,
        setErrorfiled
      );
    }
  };

  return (
    <div
      className="modal fade modal-add-moderators"
      id="ModalAddModerators"
      tabIndex="-1"
      aria-labelledby="ModalAddModeratorsLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="header">
            <h6>إضافة مشرف جديد</h6>

            <button
              type="button"
              className="btn btn-close-modal-add"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="btn-close-modal-add--moderators"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18 6L6 18"
                  stroke="#1C1D22"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 6L18 18"
                  stroke="#1C1D22"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="body">
            <form action="">
              <div className="input-froms">
                <InputName
                  Value={name}
                  setValue={setName}
                  ErrorMessage={ErrorInput}
                  setErrorMessage={setErrorInput}
                />
                <InputNumber
                  Value={number}
                  setValue={setNumber}
                  ErrorMessage={ErrorInput}
                  setErrorMessage={setErrorInput}
                />
                <InputPassword
                  Value={password}
                  setValue={setPassword}
                  ErrorMessage={ErrorInput}
                  setErrorMessage={setErrorInput}
                />
                <InputConfirmPassword
                  Value={confirmpassword}
                  setValue={setConfirmpassword}
                  Password={password}
                  ErrorMessage={ErrorInput}
                  setErrorMessage={setErrorInput}
                />
                <InputValidity
                  Value={Validity}
                  SetValue={setValidity}
                  ErrorValidity={ErrorValidity}
                  setErrorValidity={setErrorValidity}
                />
                {errorfiled && <span className="errorfiled">{errorfiled}</span>}
              </div>

              <div className="form-group-button">
                <button
                  type="submit"
                  className="btn btn-submit"
                  onClick={(e) => {
                    OnSubmit(e);
                  }}
                >
                  <span>إضافة</span>
                </button>
                <button
                  type="button"
                  className="btn btn-line-susees"
                  data-bs-dismiss="modal"
                >
                  <span>إلغاء</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddModerators;

const InputName = (props) => {
  const { Value, setValue, ErrorMessage, setErrorMessage } = props;

  const OnChinge = (e) => {
    e.preventDefault();
    setValue(e.target.value);
    setErrorMessage((prevState) => ({
      ...prevState,
      name: "",
    }));
  };
  return (
    <div className="input-group_row">
      <div
        className={
          ErrorMessage.name === ""
            ? "input-group-data"
            : "input-group-data error"
        }
      >
        <input
          type="text"
          placeholder="الاسم"
          value={Value}
          onChange={(e) => {
            OnChinge(e);
          }}
        />
      </div>
      {ErrorMessage.name && (
        <span className="error-text">{ErrorMessage.name}</span>
      )}
    </div>
  );
};

const InputNumber = (props) => {
  const { Value, setValue, ErrorMessage, setErrorMessage } = props;

  const OnChinge = (e) => {
    e.preventDefault();
    setValue(e.target.value);
    setErrorMessage((prevState) => ({
      ...prevState,
      number: "",
    }));
  };
  return (
    <div className="input-group_row">
      <div
        className={
          ErrorMessage.number === ""
            ? "input-group-data"
            : "input-group-data error"
        }
      >
        <input
          type="number"
          placeholder="الرقم العسكري"
          value={Value}
          onChange={(e) => {
            OnChinge(e);
          }}
        />
      </div>
      {ErrorMessage.number && (
        <span className="error-text">{ErrorMessage.number}</span>
      )}
    </div>
  );
};

const InputPassword = (props) => {
  const { Value, setValue, ErrorMessage, setErrorMessage } = props;
  const [toggle, setToggle] = useState(false);

  const OnChinge = (e) => {
    e.preventDefault();
    setValue(e.target.value);
    setErrorMessage((prevState) => ({
      ...prevState,
      password: "",
    }));
  };
  return (
    <div className="input-group_row">
      <div
        className={
          ErrorMessage.password === ""
            ? "input-group-password"
            : "input-group-password error"
        }
      >
        <img src="/assets/icons/icon-lock.png" alt="" />
        <input
          type={toggle === false ? "password" : "text"}
          placeholder="كلمة المرور"
          value={Value}
          onChange={(e) => {
            OnChinge(e);
          }}
        />
        <span className="toggoleimg" onClick={() => setToggle(!toggle)}>
          {toggle === false ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clipPath="url(#clip0_761_6712)">
                <path
                  d="M6.60008 2.82664C7.05897 2.71923 7.52879 2.66554 8.00008 2.66664C12.6667 2.66664 15.3334 7.99998 15.3334 7.99998C14.9287 8.75705 14.4461 9.4698 13.8934 10.1266M9.41341 9.41331C9.23032 9.60981 9.00951 9.76741 8.76418 9.87673C8.51885 9.98604 8.25402 10.0448 7.98547 10.0496C7.71693 10.0543 7.45019 10.0049 7.20115 9.9043C6.95212 9.80371 6.7259 9.654 6.53598 9.46408C6.34606 9.27416 6.19634 9.04794 6.09575 8.7989C5.99516 8.54987 5.94577 8.28312 5.9505 8.01458C5.95524 7.74604 6.01402 7.48121 6.12333 7.23587C6.23264 6.99054 6.39025 6.76974 6.58675 6.58664M11.9601 11.96C10.8205 12.8286 9.43282 13.3099 8.00008 13.3333C3.33341 13.3333 0.666748 7.99998 0.666748 7.99998C1.49601 6.45457 2.64617 5.10438 4.04008 4.03998L11.9601 11.96Z"
                  stroke="black"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.666748 0.666656L15.3334 15.3333"
                  stroke="black"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_761_6712">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.10755 5.86813C9.10755 7.03213 8.16355 7.97547 6.99955 7.97547C5.83555 7.97547 4.89221 7.03213 4.89221 5.86813C4.89221 4.70347 5.83555 3.76013 6.99955 3.76013C8.16355 3.76013 9.10755 4.70347 9.10755 5.86813Z"
                stroke="#130F26"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.99859 10.736C9.53725 10.736 11.8593 8.91067 13.1666 5.868C11.8593 2.82533 9.53725 1 6.99859 1H7.00125C4.46258 1 2.14059 2.82533 0.833252 5.868C2.14059 8.91067 4.46258 10.736 7.00125 10.736H6.99859Z"
                stroke="#130F26"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </span>
      </div>
      {ErrorMessage.password && (
        <span className="error-text">{ErrorMessage.password}</span>
      )}
    </div>
  );
};
const InputConfirmPassword = (props) => {
  const { Value, setValue, Password, ErrorMessage, setErrorMessage } = props;
  const [toggle, setToggle] = useState(false);

  const OnChinge = (e) => {
    e.preventDefault();
    setValue(e.target.value);

    if (e.target.value !== Password) {
      setErrorMessage((prevState) => ({
        ...prevState,
        confirmpassword: "كلمة المرور غير متطابقة",
      }));
    } else {
      setErrorMessage((prevState) => ({
        ...prevState,
        confirmpassword: "",
      }));
    }
  };
  return (
    <div className="input-group_row">
      <div
        className={
          ErrorMessage.confirmpassword === ""
            ? "input-group-password"
            : "input-group-password error"
        }
      >
        <img src="/assets/icons/icon-lock.png" alt="" />
        <input
          type={toggle === false ? "password" : "text"}
          placeholder="تأكيد كلمة المرور"
          value={Value}
          onChange={(e) => {
            OnChinge(e);
          }}
        />
        <span className="toggoleimg" onClick={() => setToggle(!toggle)}>
          {toggle === false ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clipPath="url(#clip0_761_6712)">
                <path
                  d="M6.60008 2.82664C7.05897 2.71923 7.52879 2.66554 8.00008 2.66664C12.6667 2.66664 15.3334 7.99998 15.3334 7.99998C14.9287 8.75705 14.4461 9.4698 13.8934 10.1266M9.41341 9.41331C9.23032 9.60981 9.00951 9.76741 8.76418 9.87673C8.51885 9.98604 8.25402 10.0448 7.98547 10.0496C7.71693 10.0543 7.45019 10.0049 7.20115 9.9043C6.95212 9.80371 6.7259 9.654 6.53598 9.46408C6.34606 9.27416 6.19634 9.04794 6.09575 8.7989C5.99516 8.54987 5.94577 8.28312 5.9505 8.01458C5.95524 7.74604 6.01402 7.48121 6.12333 7.23587C6.23264 6.99054 6.39025 6.76974 6.58675 6.58664M11.9601 11.96C10.8205 12.8286 9.43282 13.3099 8.00008 13.3333C3.33341 13.3333 0.666748 7.99998 0.666748 7.99998C1.49601 6.45457 2.64617 5.10438 4.04008 4.03998L11.9601 11.96Z"
                  stroke="black"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.666748 0.666656L15.3334 15.3333"
                  stroke="black"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_761_6712">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.10755 5.86813C9.10755 7.03213 8.16355 7.97547 6.99955 7.97547C5.83555 7.97547 4.89221 7.03213 4.89221 5.86813C4.89221 4.70347 5.83555 3.76013 6.99955 3.76013C8.16355 3.76013 9.10755 4.70347 9.10755 5.86813Z"
                stroke="#130F26"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.99859 10.736C9.53725 10.736 11.8593 8.91067 13.1666 5.868C11.8593 2.82533 9.53725 1 6.99859 1H7.00125C4.46258 1 2.14059 2.82533 0.833252 5.868C2.14059 8.91067 4.46258 10.736 7.00125 10.736H6.99859Z"
                stroke="#130F26"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </span>
      </div>
      {ErrorMessage.confirmpassword && (
        <span className="error-text">{ErrorMessage.confirmpassword}</span>
      )}
    </div>
  );
};
export const InputValidity = (props) => {
  const { Value, SetValue, ErrorValidity } = props;
  const [name, setName] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    GetData(setData, setIsLoading);
  }, []);

  if (isLoading)
    return (
      <div className="form-group">
        <div className="btn-group">
          <button
            type="button"
            className={
              ErrorValidity
                ? "is-invalid btn  dropdown-toggle"
                : "btn  dropdown-toggle"
            }
          >
            <span className="size_body weight_500 text">
              {Value === "" ? "الصلاحية" : name}{" "}
            </span>
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M6.5 9L12.5 15L18.5 9"
                  stroke="#5E6366"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>
        </div>
        {ErrorValidity && <span className="errorfiled">{ErrorValidity}</span>}
      </div>
    );

  return (
    <div className="form-group">
      <div className="btn-group">
        <Select
          options={data}
          direction="rtl"
          onChange={(values) => {
            setName(values.label);
            SetValue(values.value);
          }}
          placeholder={Value === "" ? "الصلاحية" : name}
          className="w-100 react-select-container"
          classNamePrefix="react-select"
        />
      </div>
      {ErrorValidity && <span className="error-text">{ErrorValidity}</span>}
    </div>
  );
};

const GetData = async (setData, setIsLoading) => {
  const options = {
    method: "get",
    url: `${Api}Role/GetRolesSelectList`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      for (let i = 0; i < response.data.responseObject.length; i++) {
        setData((oldArray) => [
          ...oldArray,
          {
            value: response.data.responseObject[i].id,
            label: response.data.responseObject[i].name,
          },
        ]);
      }
      // remove duplicate values from array
      setData((oldArray) =>
        oldArray.filter(
          (v, i, a) => a.findIndex((t) => t.value === v.value) === i
        )
      );
      setIsLoading(false);
    })
    .catch(function (error) {
      setIsLoading(false);
    });
};

const CreateAccount = async (
  name,
  number,
  password,
  confirmpassword,
  Validity,
  setErrorfiled
) => {
  var data = new FormData();
  data.append("fullName", name);
  data.append("userName", number);
  data.append("password", password);
  data.append("RoleIds[0]", Validity);
  data.append("confirmPassword", confirmpassword);

  const options = {
    method: "post",
    url: `${Api}ApplicationDbUser/AddUser`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description == "Success") {
        swal("تم إضافة مشرف جديد", {
          icon: "success",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            window.location.reload();
          }
        });
      } else {
        setErrorfiled(`${response.data.responseStatus.errors[0].errorMessage}`);
      }
    })
    .catch(function (error) {});
};
