import React from "react";
import StudentsTop from "./students-top";
import StudentsTable from "./students-table";

const StudentsContent = (props) => {
  const {
    ArrayData,
    perpage,
    pagenumber,
    setLoading,
    PageSize,
    setPageSize,
    classRoomNumber,
    classcode,
  } = props;
  return (
    <div className="students-content">
      <StudentsTop setLoading={setLoading} classRoomNumber={classRoomNumber} />
      <StudentsTable
        ArrayData={ArrayData}
        perpage={perpage}
        pagenumber={pagenumber}
        setLoading={setLoading}
        PageSize={PageSize}
        setPageSize={setPageSize}
        classRoomNumber={classRoomNumber}
        classcode={classcode}
      />
    </div>
  );
};

export default StudentsContent;
