import React from "react";
import ListStudentsTable from "./list-students-table";
import ListStudentsTop from "./list-students-top";

const ListStudentsContent = (props) => {
  const { ArrayData, reTest } = props;
  return (
    <div className="students-content">
      <ListStudentsTop reTest={reTest} />
      <ListStudentsTable ArrayData={ArrayData} />
    </div>
  );
};

export default ListStudentsContent;
