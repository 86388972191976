import React, { useEffect, useState } from "react";
import { Api } from "../../../api/index.js";

import NavbarLeft from "../../../components/navbar/navbar_left";
import NavbarTop from "../../../components/navbar/navbar_top";
import ExamsRepetitonContent from "../component/exam-repetiton/exams-repetiton-content";
import { useParams } from "react-router";
import Loading from "../../../components/loading/loading.jsx";
import axiosInstance from "../../../api/axios_instance.jsx";

const ExamsRepetiton = () => {
  const { id } = useParams();

  const ObJectArrayData = [
    {
      id: 2,
      href: `/exams/page:${1}/search:${""}`,
      title: "الاختبارات",
    },
  ];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetData(id, setData, setLoading);
  }, [id]);
  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <ExamsRepetitonContent Arraydata={data} />
        </div>
      </div>
    </section>
  );
};

export default ExamsRepetiton;

const GetData = async (id, setData, setLoading) => {
  const options = {
    method: "post",
    url: `${Api}Test/GetTestData`,
    headers: Headers,
    data: {
      id: id,
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
