import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import NavbarLeft from "../../../components/navbar/navbar_left.jsx";
import NavbarTop from "../../../components/navbar/navbar_top.jsx";
import ChoiceAddQuestionContent from "../component/edit-question-content/index.jsx";
import axiosInstance from "../../../api/axios_instance.jsx";
import { useSearchParams } from "react-router-dom";
import Loading from "../../../components/loading/loading.jsx";
import { Api } from "../../../api/index.js";
import { Headers } from "../../../api/actions.js";

const EditQuestion = () => {
  const { id } = useParams();
  const { subjectId } = useSearchParams();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  useEffect(() => {
    GetData(id, setData, setLoading);
  }, [id]);

  const ObJectArrayData = [
    {
      id: 2,
      href: `/questionbanck/add-all/page:1/${subjectId}`,
      title: "بنك الاسئلة",
    },
  ];
  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <ChoiceAddQuestionContent data={data} />
        </div>
      </div>
    </section>
  );
};

export default EditQuestion;

const GetData = async (id, setData, setLoading) => {
  var data = new FormData();
  data.append("id", id);
  let answers;

  const options = {
    method: "post",
    url: `${Api}Question/GetQuestionById`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(async function (response) {
      if (response.data.responseObject.groupId)
        answers = (
          await axiosInstance({
            ...options,
            url: `${Api}Question/GetMatchingQuestionByGroupId`,
            data: { id },
          })
        ).data.responseObject.matchingQuestions;

      setData({
        ...response.data.responseObject,
        answers:
          answers?.map((itm) => ({ ...itm, answerId: itm.questionId })) ||
          response.data.responseObject.answers,
      });
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
