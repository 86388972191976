import React, { useEffect, useState } from "react";
import { Headers } from "../../../../../api/actions";
import { Api } from "../../../../../api";
import axios from "axios";
import { useParams } from "react-router";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import IconDelete from "../../../../../components/image/icon-delete.png";
import axiosInstance from "../../../../../api/axios_instance";

const DeliveryContent = (props) => {
  const { Type, setType } = props;
  const { subjectid } = useParams();
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const SubjectId = subjectid.slice(
    subjectid.indexOf(":") + 1,
    subjectid.length
  );
  const [answerAll, setAnswerAll] = useState([
    {
      answerId: Math.random() * 1000,
      questionName: "",
      answerName: "",
    },
  ]);
  const [activeButton, setActiveButton] = useState(false);

  const SubmitAction = (e) => {
    e.preventDefault();
    if (
      answerAll[answerAll.length - 1].questionName === "" ||
      answerAll[answerAll.length - 1].answerName === ""
    ) {
      setError(true);
    } else {
      setError(false);
      SendData(Type, SubjectId, answerAll, setActiveButton, navigate);
      setActiveButton(true);
    }
  };

  return (
    <>
      <br />
      <SelectBoxTypeAnswer Type={Type} setType={setType} />
      <DeliveryCard Answers={answerAll} setAnswerAll={setAnswerAll} />
      <AddInput
        setAnswerAll={setAnswerAll}
        answerAll={answerAll}
        setError={setError}
      />
      {error && <p className="text-danger">يرجى ملء جميع الحقول</p>}
      <ButtonsAll SubmitAction={SubmitAction} activeButton={activeButton} />
    </>
  );
};

export default DeliveryContent;

const SelectBoxTypeAnswer = (props) => {
  const { Type, setType } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    GetData(setData, setLoading);
  }, []);
  if (loading === false) {
    return (
      <div className="select-box">
        <label htmlFor="">نوع السؤال</label>
        <input
          type="text"
          name=""
          id=""
          className={" form-control"}
          value={"جاري التحميل"}
          disabled
        />
      </div>
    );
  }
  return (
    <div className="select-box" style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          left: "20px",
          bottom: "50px",
          color: "gray",
        }}
      >
        ⮟
      </div>
      <label htmlFor="">نوع السؤال</label>
      <select
        name=""
        id=""
        className={"form-control"}
        defaultValue={Type}
        onChange={(e) => {
          e.preventDefault();
          setType(e.target.value);
        }}
      >
        {data.map((item, index) => {
          return (
            <option key={index} value={item.id}>
              {item.nameAr}
            </option>
          );
        })}
      </select>
    </div>
  );
};

const DeliveryCard = (props) => {
  const { Answers, setAnswerAll } = props;

  return (
    <div className="delivery-row">
      <div className="delivery-row__list">
        <div className="delivery-row__left">
          <span className="title-sub"> العمود الأول</span>
          <span className="title-sub"> السؤال</span>
        </div>
        <div className="delivery-row__right">
          <span className="title-sub"> العمود الثاني</span>
          <span className="title-sub"> الأجابه</span>
        </div>
      </div>
      {Answers.map((item, index) => {
        return (
          <DeliveryCardList
            key={item.answerId}
            item={item}
            index={index}
            Answers={Answers}
            setAnswerAll={setAnswerAll}
          />
        );
      })}
      <div className="d-flex justify-content-between">
        <span
          style={{
            flex: "1",
          }}
        ></span>
        <span
          className="blue-text"
          style={{
            flex: "1",
          }}
        >
          سيتم ظهور الإجابات بشكل عشوائي للطلاب
        </span>
      </div>
    </div>
  );
};

const DeliveryCardList = (props) => {
  const { item, index, Answers, setAnswerAll } = props;

  const [question, setQuestion] = useState(item.questionName);
  const [answer, setAnswer] = useState(item.answerName);

  const ChingeQuestion = (e) => {
    e.preventDefault();
    setQuestion(e.target.value);
    Answers[index].questionName = e.target.value;
    setAnswerAll(Answers);
  };

  const ChingeAnswer = (e) => {
    e.preventDefault();
    setAnswer(e.target.value);
    Answers[index].answerName = e.target.value;
    setAnswerAll(Answers);
  };

  const RemoveItem = (e) => {
    e.preventDefault();
    Answers.splice(index, 1);
    setAnswerAll([...Answers]);
  };

  return (
    <div className="delivery-row__list">
      <div className="delivery-row__left">
        <div className="data-quection">
          <span className="number">
            <span>{index + 1}</span>
          </span>
          <input
            type="text"
            name=""
            id=""
            className="form-control"
            value={question}
            onChange={ChingeQuestion}
          />
        </div>
      </div>
      <div className="delivery-row__right">
        <div className="data-quection">
          <span className="number">
            <span>{index + 1}</span>
          </span>
          <input
            type="text"
            name=""
            id=""
            className="form-control "
            value={answer}
            onChange={ChingeAnswer}
          />
        </div>
      </div>

      {index === 0 ? null : (
        <button
          type="button"
          className="btn btn-remove"
          onClick={(e) => {
            RemoveItem(e);
          }}
        >
          <img src={IconDelete} alt="" />
        </button>
      )}
    </div>
  );
};

const ButtonsAll = (props) => {
  const { SubmitAction, activeButton } = props;
  const navigate = useNavigate();

  return (
    <div className="add-question-content__card__buttons">
      <button
        type="button"
        className="btn btn-save"
        disabled={activeButton}
        onClick={(e) => {
          SubmitAction(e);
        }}
      >
        <span>حفظ</span>
      </button>
      <button
        type="button"
        className="btn btn-cansal"
        onClick={(e) => {
          e.preventDefault();
          navigate(-1);
        }}
      >
        <span>الغاء</span>
      </button>
    </div>
  );
};

const AddInput = (props) => {
  const { setAnswerAll, answerAll, setError } = props;

  const AddInput = (e) => {
    e.preventDefault();
    if (answerAll.length === 0) {
      setError(false);
      answerAll.push({ questionName: "", answerName: "" });
      setAnswerAll([...answerAll]);
    } else {
      if (
        answerAll[answerAll.length - 1].questionName === "" ||
        answerAll[answerAll.length - 1].answerName === ""
      ) {
        setError(true);
      } else {
        setError(false);
        answerAll.push({ questionName: "", answerName: "" });
        setAnswerAll([...answerAll]);
      }
    }
  };
  return (
    <div className="multiple-choice__addInput">
      <button
        type="button"
        className="btn button button-next"
        onClick={AddInput}
      >
        أضافه أختيار جديد
      </button>
    </div>
  );
};

// Send Data to API
const SendData = async (
  Type,
  SubjectId,
  answerAll,
  setActiveButton,
  navigate
) => {
  var Formdata = new FormData();
  for (var i = 0; i < answerAll.length; i++) {
    Formdata.append(
      `answers[${i}].questionName`,
      JSON.stringify(answerAll[i].questionName)
    );
    Formdata.append(`answers[${i}].answerName`, answerAll[i].answerName);
  }

  const options = {
    method: "post",
    url: `${Api}Question/AddEditMatchingQuestion`,
    headers: Headers,
    data: {
      subjectId: SubjectId,
      answerTypeId: Number(Type),
      matchingQuestions: answerAll,
    },
  };
  await axiosInstance(options)
    .then(function (response) {
      setActiveButton(true);
      swal("تم أضافه سؤال بنجاح", {
        icon: "success",
        confirmButtonColor: "#d33",
        button: {
          text: "إغلاق",
          closeModal: true,
        },
      }).then((result) => {
        if (result === true) {
          navigate(-1);
        }
      });
    })
    .catch(function (error) {});
};

const GetData = async (setData, setLoading) => {
  const options = {
    method: "GET",
    url: `${Api}AnswerType/GetAnswerTypes`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
