/* eslint-disable eqeqeq */
import React, { useState } from "react";
import TrueAndFalseCard from "./true-and-false";
import MultipleChoice from "./multiple-choice";
import DeliveryContent from "./delivery";

const Question = ({ data }) => {
  const [Type, setType] = useState(data.questionTypeId);
  const [question, setQuestion] = useState(data.name);

  return (
    <>
      {Type == 1 ? (
        <MultipleChoice
          Type={Type}
          setType={setType}
          question={question}
          setQuestion={setQuestion}
          answers={data.answers}
        />
      ) : Type == 2 ? (
        <TrueAndFalseCard
          Type={Type}
          setType={setType}
          question={question}
          setQuestion={setQuestion}
          answers={data.answers}
        />
      ) : Type == 3 ? (
        <DeliveryContent Type={Type} setType={setType} answers={data.answers} />
      ) : null}
    </>
  );
};

export default Question;
