import React, { useEffect, useState } from "react";
import { Headers } from "../../../../../api/actions";
import { Api } from "../../../../../api";
import axios from "axios";
import { useNavigate } from "react-router";
import axiosInstance from "../../../../../api/axios_instance";

const TrueAndFalseCard = (props) => {
  const { Answers } = props;

  const [question, setQuestion] = useState(Answers.name);
  const [answerAll, setAnswerAll] = useState([...Answers.answers]);

  return (
    <div className="add-question-content__card">
      <h6>عرض السؤال</h6>
      {/* Question Name */}
      <textarea
        rows="3"
        value={question}
        placeholder="اكتب نص السؤال"
        name="add-question-textarea"
        required
        disabled
        className={question === "" ? "form-control is-invalid" : "form-control"}
        onChange={(e) => {
          e.preventDefault();
          setQuestion(e.target.value);
        }}
      ></textarea>
      <SelectBoxTypeAnswer selectType={Answers.questionTypeId} />
      <br />
      <>
        <div className="select-box">
          <label htmlFor="">حالة السؤال</label>
          <input
            type="text"
            name=""
            id=""
            className={" form-control"}
            value={
              answerAll.find((itm) => itm.name === "صح")?.isCorrectAnswer
                ? "صح"
                : "خطأ"
            }
            disabled
          />
        </div>
        {/* {answerAll.map((item, index) => {
          return (
            <div key={index}>
              <MultipleChoiceInputRow
                setAnswerAll={setAnswerAll}
                answerAll={answerAll}
                Item={item}
                Index={index}
              />
            </div>
          );
        })} */}
        {/* <ButtonsAll  /> */}
      </>
    </div>
  );
};

export default TrueAndFalseCard;

const SelectBoxTypeAnswer = (props) => {
  const { selectType } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    GetData(setData, setLoading);
  }, []);
  return (
    <div className="select-box">
      <label htmlFor="">نوع السؤال</label>
      <select
        className={"form-control"}
        name="horns"
        id="horns"
        value={selectType}
        disabled
      >
        {loading === false ? (
          <option value="0">جاري التحميل</option>
        ) : (
          <>
            {data.map((item, index) => {
              return (
                <option key={index} value={item.id}>
                  {item.nameAr}
                </option>
              );
            })}
          </>
        )}
      </select>
    </div>
  );
};

const MultipleChoiceInputRow = (props) => {
  const { setAnswerAll, answerAll, Item, Index } = props;
  const [select, setSelect] = useState(Item.isCorrectAnswer);

  const SelectArray = [
    {
      id: 1,
      value: true,
      name: "صحيح",
    },
    {
      id: 2,
      value: false,
      name: "خطأ",
    },
  ];

  const onChingeSelect = (e) => {
    e.preventDefault();
    setSelect(!select);
    Item.isCorrectAnswer = !select;
    answerAll[Index] = Item;
    setAnswerAll([...answerAll]);
  };
  return (
    <div className="multiple-choice__row">
      <div className="select">
        <label htmlFor="">حاله الأجابه </label>
        <select
          name=""
          id=""
          disabled
          value={select}
          className={select === true ? "success" : "error"}
          onChange={onChingeSelect}
        >
          {SelectArray.map((item, index) => {
            return (
              <option key={index} value={item.value}>
                {item.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="inputs">
        <label htmlFor="">الأجابه</label>
        <input
          type="text"
          name=""
          id=""
          className={
            select === true ? "success form-control" : "error form-control"
          }
          value={Item.name}
          disabled
        />
      </div>
    </div>
  );
};

const ButtonsAll = () => {
  const navigate = useNavigate();

  return (
    <div className="add-question-content__card__buttons">
      <button
        type="button"
        className="btn btn-cansal"
        onClick={(e) => {
          e.preventDefault();
          navigate(-1);
        }}
      >
        <span>رجوع</span>
      </button>
    </div>
  );
};

// Get Data from API
const GetData = async (setData, setLoading) => {
  const options = {
    method: "GET",
    url: `${Api}AnswerType/GetAnswerTypes`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
