import React, { useEffect, useState } from "react";
//import { useParams } from 'react-router'
import axios from "axios";
import { Api } from "../../api";
import { Headers } from "../../api/actions";
import Loading from "../../components/loading/loading";

import NavbarLeft from "../../components/navbar/navbar_left";
import NavbarTop from "../../components/navbar/navbar_top";
import PowersContent from "./component/powers-content";
import { useParams } from "react-router";
import axiosInstance from "../../api/axios_instance";

const Powers = () => {
  const { search } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const searchKeyword = search.slice(search.indexOf(":") + 1, search.length);

  useEffect(() => {
    GetData(setData, setLoading, searchKeyword);
  }, [searchKeyword]);

  const ObJectArrayData = [
    {
      id: 2,
      // href: `/powers/page:${1}/search:${""}`,
      href: `/powers/search:`,
      title: "الصلاحيات",
    },
  ];

  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <PowersContent Arraydata={data} setLoading={setLoading} />
        </div>
      </div>
    </section>
  );
};

export default Powers;

const GetData = async (setData, setLoading, searchKeyword) => {
  const search = searchKeyword === "" ? "" : `?SearchKeyword=${searchKeyword}`;
  const options = {
    method: "get",
    url: `${Api}Role/GetRoles${search}`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
