import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import NavbarLeft from "../../components/navbar/navbar_left";
import NavbarTop from "../../components/navbar/navbar_top";
import Loading from "../../components/loading/loading";
import axiosInstance from "../../api/axios_instance";
import { Api } from "../../api";
import swal from "sweetalert";

export default function Agreement() {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(true);
  const quillRef = useRef(null);

  useEffect(() => {
    const quill = quillRef?.current?.getEditor();
    if (!quill) return;
    quill.format("align", "right");
    quill.format("direction", "rtl");
  }, [loading]);
  useEffect(() => {
    GetData(setValue, setLoading);
  }, []);
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "direction",
    "list",
    "bullet",
    "indent",
    "align",
    "color",
    "background",
  ];
  const modules = {
    toolbar: [
      [{ header: [] }, { font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [{ direction: "rtl" }],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const ObJectArrayData = [
    {
      id: 2,
      //   href: `/agreement`,
      title: "اتفاقية الامتحان",
    },
  ];
  const onSubmit = () => {
    SubmitData(value);
  };

  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <div
            style={{
              marginTop: "4rem",
            }}
          >
            <div
              className="container"
              style={{
                padding: "1.25rem",
                background: "white",
              }}
            >
              <h3 style={{ padding: "14px" }}>تعديل نص اتفاقية الاختبارات</h3>

              <div dir="auto">
                <ReactQuill
                  ref={quillRef}
                  theme="snow"
                  value={value}
                  onChange={setValue}
                  modules={modules}
                  formats={formats}
                  style={{ height: "12rem" }}
                />
              </div>
              <div style={{ marginTop: "4rem" }} dir="auto">
                <h3 style={{ padding: "14px" }}>معاينة للنص</h3>
                <div
                  style={{
                    background: "#f5f6fb",
                    borderRadius: "20px",
                    maxHeight: "",
                  }}
                  className="ql-editor"
                  dangerouslySetInnerHTML={{ __html: value }}
                ></div>
                <div className="exams-content-add__form__buttons">
                  <button className="btn btn-primary" onClick={onSubmit}>
                    حفظ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
const GetData = async (setData, setLoading) => {
  setLoading(false);
  const options = {
    method: "get",
    url: `${Api}Setting/GetAgreementValue`,
    headers: Headers,
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description === "Error") {
        swal(response.data.responseStatus.errors[0].errorMessage, {
          icon: "error",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {});
        return;
      }
      setData(response.data.responseObject);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};

const SubmitData = async (agreement) => {
  const options = {
    method: "post",
    url: `${Api}Setting/EditAgreementValue`,
    headers: Headers,
    data: { Agreement: agreement },
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description === "Error") {
        swal(response.data.responseStatus.errors[0].errorMessage, {
          icon: "error",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {});
      } else {
        swal("تم التعديل بنجاح", {
          icon: "success",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          window.location.reload();
        });
      }
    })
    .catch(function (error) {});
};
